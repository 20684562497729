import React, { useState, useEffect, useRef, useMemo } from 'react';
import generateRandomKey from '../../../components/GenerateRandomKey';
import FabricsCategoriesItem from './FabricsCategoriesItem';
import { IoCloseSharp, IoChevronDown, IoChevronUp, IoAdd, IoRemove } from "react-icons/io5";
import TextParser from './TextParser';

const FabricFilter = ({ hp, sp, slug, topText, middleText, bottomText, groupOrder }) => {
    const [filteredFabrics, setFilteredProducts] = useState([]);
    const [activeFilter, setActiveFilter] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [checkboxes, setCheckboxes] = useState({});
    const [filtersActive, setFiltersActive] = useState(false);
    const [lastSlug, setLastSlug] = useState(null);
    const filterDropdownRef = useRef(null);
    const [showAllFilters, setShowAllFilters] = useState(false);
    const [firstSelectedFilter, setFirstSelectedFilter] = useState(null);
    const maxVisibleFilters = 5;

    const fabrics = useMemo(() => {
        const fabricsWithHighPerformance = Object.keys(hp || {}).reduce((acc, key) => {
            acc[`hp-${key}`] = { ...hp[key], performance: 'High Performance' };
            return acc;
        }, {});
    
        const fabricsWithStandardPerformance = Object.keys(sp || {}).reduce((acc, key) => {
            acc[`sp-${key}`] = { ...sp[key], performance: 'Standard Performance' };
            return acc;
        }, {});
    
        return {
            ...fabricsWithHighPerformance,
            ...fabricsWithStandardPerformance,
        };
    }, [hp, sp]);
    
    
    useEffect(() => {
        const savedFilters = localStorage.getItem(`fabricsFilter`);
        const storedSlug = localStorage.getItem('lastSlug');
        if (savedFilters && storedSlug === slug) {
            setSelectedFilters(JSON.parse(savedFilters));
        }
    }, [slug]);


    useEffect(() => {
        localStorage.setItem(`fabricsFilter`, JSON.stringify(selectedFilters));
    }, [selectedFilters, slug]);


    useEffect(() => {
        const storedSlug = localStorage.getItem('lastSlug');
        if (storedSlug) {
            setLastSlug(storedSlug);
        }
    }, []);


    useEffect(() => {
        if (lastSlug !== slug) {
            localStorage.setItem('lastSlug', slug);
            setLastSlug(slug);
        }
    }, [slug, lastSlug]);


    useEffect(() => {
        if (lastSlug && lastSlug !== slug) {
            localStorage.removeItem(`lastSlug`);
        }
    }, [slug, lastSlug]);


    useEffect(() => {
        setFilteredProducts(Object.values(fabrics)); // Ensure it's an array
        initializeCheckboxes(fabrics);
    }, [fabrics]);


    useEffect(() => {
        const { filteredProducts, availableFilters } = applyFilters(fabrics, selectedFilters);
    
        setFilteredProducts(filteredProducts);
        setCheckboxes(createCheckboxState(availableFilters, selectedFilters));
        setFiltersActive(Object.keys(selectedFilters).length > 0);
    }, [selectedFilters, fabrics]);


    useEffect(() => {
        const hasActiveFilters = Object.values(selectedFilters).some(filter => filter.length > 0);
        setFiltersActive(hasActiveFilters);
    }, [selectedFilters]);


    useEffect(() => {
        function handleClickOutside(event) {
            if (
                filterDropdownRef.current &&
                !filterDropdownRef.current.contains(event.target) &&
                !event.target.closest(".filter-button")
            ) {
                setActiveFilter(null);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    const initializeCheckboxes = (productsData) => {
        const allFilters = extractFilters(productsData);
        setCheckboxes(createCheckboxState(allFilters, {}));
    };


    const extractFilters = (productsData) => {
        const allFilters = {};
        const filterOrder = [];
        const productsArray = Object.values(productsData);

        productsArray.forEach(product => {
            if (product?.filters) {
                Object.entries(product?.filters).forEach(([key, values]) => {
                    if (!allFilters[key]) {
                        allFilters[key] = new Set();
                        filterOrder.push(key);
                    }
                    [].concat(values).forEach(value => allFilters[key].add(value));
                });
            }
        });

        const orderedFilters = {};
        filterOrder.forEach(key => {
            orderedFilters[key] = allFilters[key] || new Set(); // Ensure the order is maintained even for empty filters
        });

        return orderedFilters;
    };


    const createCheckboxState = (availableFilters, selectedFilters) => {
        const checkboxStates = {};
        Object.keys(availableFilters).forEach(key => {
            checkboxStates[key] = {};
            availableFilters[key].forEach(option => {
                checkboxStates[key][option] = selectedFilters[key]?.includes(option) || false;
            });
        });
        return checkboxStates;
    };


    const applyFilters = (productsData, selectedFilters) => {
        const productsArray = Array.isArray(productsData) ? productsData : Object.values(productsData);
    
        // Filter the products based on selected filters
        const filteredProducts = productsArray.filter(product =>
            Object.entries(selectedFilters).every(([key, options]) =>
                options.length === 0 || options.some(option => product?.filters[key]?.includes(option))
            )
        );
    
        // Dynamically recalculate available options, but ensure all initial options remain available for the first selected filter
        const availableFilters = {};
        Object.values(fabrics).forEach(product => {
            Object.entries(product.filters).forEach(([key, values]) => {
                if (!availableFilters[key]) {
                    availableFilters[key] = new Set();
                }
                [].concat(values).forEach(value => availableFilters[key].add(value));
            });
        });
    
        // Include only the available options from filtered products for other filters
        if (firstSelectedFilter) {
            Object.values(filteredProducts).forEach(product => {
                Object.entries(product.filters).forEach(([key, values]) => {
                    if (!availableFilters[key]) {
                        availableFilters[key] = new Set();
                    }
                    [].concat(values).forEach(value => availableFilters[key].add(value));
                });
            });
        }
    
        return {
            filteredProducts,
            availableFilters: Object.keys(availableFilters).reduce((acc, key) => {
                acc[key] = Array.from(availableFilters[key]);
                return acc;
            }, {}),
        };
    };


    const handleCheckboxToggle = (filterKey, option) => {
        setSelectedFilters(prev => {
            const updatedFilters = {
                ...prev,
                [filterKey]: prev[filterKey]?.includes(option)
                    ? prev[filterKey].filter(item => item !== option)
                    : [...(prev[filterKey] || []), option],
            };
    
            if (!firstSelectedFilter) {
                setFirstSelectedFilter(filterKey); // Track the first selected filter
            }
    
            return updatedFilters;
        });
    };


    const handleFilterClick = (filterKey) => {
        setActiveFilter(activeFilter === filterKey ? null : filterKey);
    };


    const handleClearAllFilters = () => {
        setSelectedFilters({});
        setFiltersActive(false);
        setFilteredProducts([]);
    };


    const handleShowAllFilters = () => {
        setShowAllFilters(!showAllFilters);
    };

    return (
        <div className='text-kb-400 space-y-1'>
            <div className='max-w-screen-2xl mx-auto py-8 px-4 md:px-8 kb-xl:px-0'>
                <div>
                    <h2 className="flex justify-center xl:justify-start text-3xl md:text-5xl xl:text-6xl text-kb-400 tracking-wide font-light pb-7">Fabrics</h2>
                    <div className="space-y-6">
                        <div className="text-kb-400 text-base">
                            {TextParser(topText)}
                        </div>
                        <div className="w-full border-t border-gray-300"></div>
                        <div className="text-kb-400 pb-4 text-2xl">Filters</div>
                    </div>
                    <div className='block space-y-6 xl:flex xl:space-y-0 xl:space-x-4 items-center'>
                        <div className='flex select-none flex-wrap'>
                        {groupOrder?.map(({ name: filterKey }, index) => {
                            // Skip if this filterKey isn't in our checkboxes
                            if (!Object.keys(checkboxes).includes(filterKey)) return null;

                            // Count how many options are actually available (with count > 0)
                            const totalCount = Object.keys(checkboxes[filterKey]).reduce((acc, option) => {
                                // For each option, we figure out how many products include it
                                const count = (firstSelectedFilter === filterKey ? Object.values(fabrics) : filteredFabrics)
                                .filter(product => product.filters[filterKey]?.includes(option))
                                .length;
                                return count > 0 ? acc + 1 : acc;
                            }, 0);

                            // If there are zero valid items in the dropdown, hide it
                            if (totalCount === 0) {
                                return null;
                            }

                            // Otherwise render the button and the dropdown as before
                            return (
                                <div
                                key={filterKey}
                                className={`relative h-[30px] ${!showAllFilters && index >= maxVisibleFilters ? 'hidden' : ''}`}
                                >
                                <button
                                    onClick={() => handleFilterClick(filterKey)}
                                    className='filter-button flex space-x-2 items-center mr-6 hover:text-kb-300'
                                >
                                    <div>{filterKey}</div>
                                    {activeFilter === filterKey ? <IoChevronUp /> : <IoChevronDown />}
                                </button>
                                {activeFilter === filterKey && (
                                    <div
                                    ref={filterDropdownRef}
                                    className='absolute top-8 left-0 border border-gray-300 rounded bg-white p-3 space-y-2 z-50 max-h-[300px] overflow-y-auto'
                                    >
                                    {Object.keys(checkboxes[filterKey])
                                        .sort((a, b) => a.localeCompare(b)) // Sort options alphabetically
                                        .map(option => {
                                        const count = (firstSelectedFilter === filterKey ? Object.values(fabrics) : filteredFabrics)
                                            .filter(product => product.filters[filterKey]?.includes(option))
                                            .length;

                                        return count > 0 ? (
                                            <div key={option} className='flex items-center space-x-2 text-sm whitespace-nowrap'>
                                            <input
                                                type="checkbox"
                                                id={`${filterKey}-${option}`}
                                                name={`${filterKey}-${option}`}
                                                checked={selectedFilters[filterKey]?.includes(option) || false}
                                                onChange={() => handleCheckboxToggle(filterKey, option)}
                                                className='border appearance-none border-kb-400 checked:bg-kb-300 checked:hover:bg-kb-300 focus:checked:bg-kb-300 focus:ring-0 focus:outline-none cursor-pointer'
                                            />
                                            <label
                                                htmlFor={`${filterKey}-${option}`}
                                                className='cursor-pointer text-kb-400 hover:text-kb-300 w-full'
                                            >
                                                {option} ({count})
                                            </label>
                                            </div>
                                        ) : null;
                                        })}
                                    </div>
                                )}
                                <br />
                                </div>
                            );
                            })}
                        </div>
                        {Object.keys(checkboxes).length > maxVisibleFilters && (
                            <button onClick={handleShowAllFilters} className='flex space-x-2 border-gray-300 px-2 hover:text-kb-300 bg-gray-100 items-center text-sm rounded'>{showAllFilters ? <div className='flex items-center space-x-2'><div>Less Filters</div> <IoRemove /></div> : <div className='flex items-center space-x-2'><div>More Filters</div> <IoAdd /></div>}</button>
                        )}
                    </div>
                    <div className='flex space-x-2 items-center select-none'>
                        {/* Display active filters */}
                        <div className='flex items-center whitespace-nowrap flex-wrap'>
                            {Object.entries(selectedFilters).map(([key, options]) => (
                                options.map(option => (
                                    <div key={`${key}-${option}`} onClick={() => handleCheckboxToggle(key, option)} className='flex space-x-2 items-center border border-gray-300 py-2 px-2 text-sm rounded hover:text-kb-300 cursor-pointer mr-2 mt-2'>
                                        <div>{key}: {option}</div>
                                        <button><IoCloseSharp /></button>
                                    </div>
                                ))
                            ))}
                            {filtersActive && (
                                <button onClick={handleClearAllFilters} className='flex space-x-2 border-gray-300 py-1 px-2 text-sm hover:text-kb-300 underline underline-offset-4 mt-2'>Clear All</button>
                            )}
                        </div>
                    </div>
                    <div>
                        {/* Display filtered fabrics */}
                        { Object.values(selectedFilters).some(filter => filter.length > 0) && filteredFabrics.length > 0 && (
                            <div style={{ paddingTop: '30px' }}>
                                <div className="text-kb-400 py-7">{filteredFabrics.length > 1 ? `Showing 1-${filteredFabrics.length} of ${filteredFabrics.length}` : 'Showing 1 item'}</div>
                                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-14">
                                    { filteredFabrics.map((fabric) => (
                                        <a key={generateRandomKey()} href={`/fabrics/${fabric.slug}`}>
                                            <FabricsCategoriesItem fabric={fabric} showPerformance={true} />
                                        </a>
                                    )) }
                                </div>
                            </div>
                        ) }

                    </div>
                </div>
            </div>
            { Object.values(selectedFilters).every(filter => filter.length === 0) && (
                <div className='max-w-screen-2xl mx-auto py-8 px-4 md:px-8 kb-xl:px-0'>
                    <div className='space-y-12'>
                        <div className='space-y-6'>
                            <div className="text-kb-400">
                                <div className="text-kb-400 pb-4 text-2xl">High Performance</div>
                                <div className="text-kb-400 text-base pb-4">
                                    {TextParser(middleText)}
                                </div>
                                <div>Showing 1-{hp.length} of {hp.length}</div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-14">
                                { hp.map((fabric, index) => (
                                    <a key={generateRandomKey()} href={`/fabrics/${fabric.slug}`}>
                                        <FabricsCategoriesItem fabric={fabric} />
                                    </a>
                                )) }
                            </div>
                        </div>
                        <div className='space-y-6'>
                            <div className="text-kb-400">
                                <div className="text-kb-400 pb-4 text-2xl">Standard Performance</div>
                                <div className="text-kb-400 text-base pb-4">
                                    {TextParser(bottomText)}
                                </div>
                                <div>Showing 1-{sp.length} of {sp.length}</div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-14">
                                { sp.map((fabric, index) => (
                                    <a key={generateRandomKey()} href={`/fabrics/${fabric.slug}`}>
                                        <FabricsCategoriesItem fabric={fabric} />
                                    </a>
                                )) }
                            </div>
                        </div>
                    </div>
                </div>
            ) }
        </div>
    );
};

export default FabricFilter;