import { useEffect, useRef, useState } from 'react';
import Layout from '../../../layout/logged_out/Layout';
import { useMegaMenu  } from '../../../lib/MegaMenuContext';
import { staticUrl } from '../../../config';
import MainSlider from '../../../sections/sliders/MainSlider';
import ProductSlider from '../../../sections/sliders/ProductSlider';
import CollectionSlider from '../../../sections/sliders/CollectionSlider';

const Index = () => {

    const { megaMenuData } = useMegaMenu();
    const [mainCarousel, setMainCarousel] = useState([]);
    const [productCarousel, setProductCarousel] = useState([]);
    const [collectionCarousel, setCollectionCarousel] = useState([]);

    const [productCarouselEnabled, setProductCarouselEnabled] = useState(false);
    const [collectionCarouselEnabled, setCollectionCarouselEnabled] = useState(false);
    const [section1Enabled, setSection1Enabled] = useState(false);
    const [section2Enabled, setSection2Enabled] = useState(false);
    const [section3Enabled, setSection3Enabled] = useState(false);

    const [section1Image1, setSection1Image1] = useState('');
    const [section1Image2, setSection1Image2] = useState('');
    const [section1URL, setSection1URL] = useState('');
    const [section1Title, setSection1Title] = useState('');
    const [section1Line1, setSection1Line1] = useState('');
    const [section1Line2, setSection1Line2] = useState('');
    //const [section1Line3, setSection1Line3] = useState('');

    const [section2Image1, setSection2Image1] = useState('');
    const [section2Image2, setSection2Image2] = useState('');
    const [section2Image3, setSection2Image3] = useState('');
    const [section2Text1, setSection2Text1] = useState('');
    const [section2Text2, setSection2Text2] = useState('');
    const [section2Text3, setSection2Text3] = useState('');
    const [section2URL1, setSection2URL1] = useState('');
    const [section2URL2, setSection2URL2] = useState('');
    const [section2URL3, setSection2URL3] = useState('');
    const [section2Title, setSection2Title] = useState('');
    const [section2Line1, setSection2Line1] = useState('');
    const [section2Line2, setSection2Line2] = useState('');
    //const [section2Line3, setSection2Line3] = useState('');

    const [section3Image1, setSection3Image1] = useState('');
    const [section3Image2, setSection3Image2] = useState('');
    const [section3Title1, setSection3Title1] = useState('');
    const [section3Description1, setSection3Description1] = useState('');
    const [section3Title2, setSection3Title2] = useState('');
    const [section3Description2, setSection3Description2] = useState('');
    const [section3URL1, setSection3URL1] = useState('');
    const [section3URL2, setSection3URL2] = useState('');

    const [mobileImage, setMobileImage] = useState('');
    const [mobileImageSlug, setMobileImageSlug] = useState('');

    useEffect(() => {
        if (!megaMenuData) {
            return;
        }

        const getMainImages = () => {
            const mainCarousel = megaMenuData.main_carousel;
            setMobileImage(megaMenuData.mobile_image);
            setMobileImageSlug(megaMenuData.mobile_slug);
            //console.log(megaMenuData)        
            if (mainCarousel) {
                const mainCarouselItems = [];
                for (let i = 1; i <= 8; i++) {
                    const carouselItem = mainCarousel[`main_carousel_${i}`];
                    const slugItem = mainCarousel[`main_slug_${i}`];
        
                    const data = {
                        relation_id: carouselItem,
                        slug: slugItem
                    };
        
                    if (carouselItem) {
                        mainCarouselItems.push(data);
                    }
                }
                setMainCarousel(prevState => [...prevState, ...mainCarouselItems]);
            }
        };
        
        getMainImages();

        const getProductImages = () => {
            const productCarousel = megaMenuData?.product_carousel;
            if (productCarousel) {

                const productCarouselItems = [];
                for (let i = 1; i <= 15; i++) {
                    const carouselItem = productCarousel[`product_carousel_${i}`];
                    if (carouselItem) {
                        productCarouselItems.push({
                            relation_id: carouselItem,
                            title: productCarousel[`product_title_${i}`],
                            slug: productCarousel[`product_slug_${i}`]
                        });
                    }
                }
                setProductCarousel(prevState => [...prevState, ...productCarouselItems]);
                setProductCarouselEnabled(productCarousel?.product_enabled);

            }
        };
        getProductImages();

        const getCollectionImages = () => {
            const collectionCarousel = megaMenuData?.collection_carousel;
            if (collectionCarousel) {

                const collectionCarouselItems = [];
                for (let i = 1; i <= 15; i++) {
                    const carouselItem = collectionCarousel[`collection_carousel_${i}`];
                    if (carouselItem) {
                        collectionCarouselItems.push({
                            relation_id: carouselItem,
                            title: collectionCarousel[`collection_title_${i}`],
                            slug: collectionCarousel[`collection_slug_${i}`]
                        });
                    }
                }
                setCollectionCarousel(prevState => [...prevState, ...collectionCarouselItems]);
                setCollectionCarouselEnabled(collectionCarousel?.collection_enabled);
            }
        };
        getCollectionImages();

        const getSections = () => {
            const sections = megaMenuData?.sections;
        
            if (sections) {
                setSection1Enabled(sections?.section_1_enabled);
                setSection2Enabled(sections?.section_2_enabled);
                setSection3Enabled(sections?.section_3_enabled);
                setSection1Image1(sections?.section_1_image_1);
                setSection1Image2(sections?.section_1_image_2);
                setSection1Title(sections?.section_1_title);
                setSection1Line1(sections?.section_1_line_1);
                setSection1Line2(sections?.section_1_line_2);
                //setSection1Line3(sections?.section_1_line_3);
                setSection1URL(sections?.section_1_slug);

                setSection2Image1(sections?.section_2_image_1);
                setSection2Image2(sections?.section_2_image_2);
                setSection2Image3(sections?.section_2_image_3);
                setSection2Text1(sections?.section_2_text_1);
                setSection2Text2(sections?.section_2_text_2);
                setSection2Text3(sections?.section_2_text_3);
                setSection2URL1(sections?.section_2_slug_1);
                setSection2URL2(sections?.section_2_slug_2);
                setSection2URL3(sections?.section_2_slug_3);
                setSection2Title(sections?.section_2_title);
                setSection2Line1(sections?.section_2_line_1);
                setSection2Line2(sections?.section_2_line_2);
                //setSection2Line3(sections?.section_2_line_3);

                setSection3Image1(sections?.section_3_image_1);
                setSection3Image2(sections?.section_3_image_2);
                setSection3Title1(sections?.section_3_title_1);
                setSection3Title2(sections?.section_3_title_2);
                setSection3Description1(sections?.section_3_description_1);
                setSection3Description2(sections?.section_3_description_2);
                setSection3URL1(sections?.section_3_slug_1);
                setSection3URL2(sections?.section_3_slug_2);

            }
        };
        
        getSections();


    }, [megaMenuData]);

    const handleSection1Click = () => {
        window.location.href = `/collections/${section1URL}`;
    };

    return (
        <>
            <Layout title='Welcome to Kingsley Bate'>
                <main className='z-0 mt-[81px] kb-menu:mt-[97px] overflow-x-hidden'>

                    <div className='kb-menu:hidden'>
                        <div className='w-full'>
                            <a href={`/collections/${mobileImageSlug}`}><img src={`${staticUrl}/img/media/landing-mobile/landing_${mobileImage}.jpg`} className='w-full' /></a>
                        </div>
                    </div>

                    <div className='hidden kb-menu:block' style={{width: 'calc(100vw + 1px)'}}>
                        { mainCarousel.length > 0 &&
                            <MainSlider images={mainCarousel} />
                        }
                    </div>

                    { productCarouselEnabled ? (
                        <>
                            <div className='py-8 xl:py-16'></div>
                            <h2 className="flex xl:pl-44 justify-center xl:justify-start text-3xl md:text-5xl xl:text-6xl uppercase text-kb-400 tracking-wider font-light">Products</h2>
                            <div className='w-full mx-auto py-8 xl:py-16 px-16 xl:px-32'>
                                { productCarousel.length > 0 &&
                                    <ProductSlider images={productCarousel} />
                                }
                            </div>
                        </>                            
                    ) : null }

                    { collectionCarouselEnabled ? (
                        <>
                            <div className='py-4 xl:py-8'></div>
                            <h2 className="flex xl:pl-44 justify-center xl:justify-start text-3xl md:text-5xl xl:text-6xl uppercase text-kb-400 tracking-wider font-light">Collections</h2>
                            <div className='w-full mx-auto py-8 xl:py-16 px-16 xl:px-32'>
                                { collectionCarousel.length > 0 &&
                                    <CollectionSlider images={collectionCarousel} />
                                }
                            </div>
                        </>
                    ) : null }

                    {section1Enabled ? (
                        <div>
                            <div className='px-4 md:px-0 md:grid md:grid-cols-3 md:gap-x-2 space-y-4 md:space-y-0 py-8 xl:py-16'>
                                <div onClick={handleSection1Click} className='hover:cursor-pointer md:grid-cols-1 min-h-[300px] md:min-h-[450px] xl:min-h-[850px] bg-cover bg-center' style={{ backgroundImage: `url(${staticUrl}/img/media/landing-square/landing-square_${section1Image1}.jpg)` }}></div>
                                <div onClick={handleSection1Click} className='hover:cursor-pointer md:grid-cols-2 md:col-span-2 min-h-[300px] md:min-h-[450px] xl:min-h-[850px] bg-cover bg-center' style={{ backgroundImage: `url(${staticUrl}/img/media/landing-rectangle/landing-rectangle_${section1Image2}.jpg)` }}></div>
                            </div>
                            <div className="w-full px-4 xl:px-8 2xl:px-44 py-8 xl:py-16">
                                <h2 className="text-3xl md:text-5xl xl:text-6xl uppercase text-kb-400 tracking-wider font-light pb-5 xl:pb-10">{section1Title}</h2>
                                {section1Line1 && (
                                    <p className='md:text-lg xl:text-xl leading-7 xl:leading-9 text-kb-400 tracking-widest'>{section1Line1}</p>
                                )}
                            {/*
                                {section1Line2 && (
                                    <p className='md:text-lg xl:text-xl leading-7 xl:leading-9 text-kb-400 tracking-widest'>{section1Line2}</p>
                                )}
                                {section1Line3 && (
                                    <p className='md:text-lg xl:text-xl leading-7 xl:leading-9 text-kb-400 tracking-widest'>{section1Line3}</p>
                                )}
                            */}
                                <div 
                                    className='md:text-lg xl:text-xl leading-7 xl:leading-9 text-kb-400 tracking-widest'
                                    dangerouslySetInnerHTML={{ __html: section1Line2 }}
                                />
                            </div>
                        </div>
                    ) : null }

                    {section2Enabled ? (
                        <div className="w-full px-4 xl:px-8 2xl:px-44 py-8 xl:py-16">
                            <section className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-3 md:gap-x-2 space-y-2 md:space-y-0 select-none">
                                <a href={`/categories/${section2URL1}`}>
                                    <div className="relative aspect-square bg-cover bg-center" style={{ backgroundImage: `url(${staticUrl}/img/media/landing-square/landing-square_${section2Image1}.jpg)` }}>
                                        <div className="overlay absolute inset-0 flex items-end justify-center opacity-0 hover:opacity-100 transition-opacity hover:cursor-pointer">
                                            <div className="relative h-[50%] bg-slate-950 w-full translate-y-0 opacity-60"></div>
                                            <div className="absolute h-[50%] w-full translate-y-0 opacity-100">
                                                <div className="overlay-text text-white text-2xl xl:text-5xl font-light uppercase tracking-widest flex h-full items-center justify-center">
                                                    <p>{section2Text1}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a href={`/categories/${section2URL2}`}>
                                    <div className="relative aspect-square bg-cover bg-center" style={{ backgroundImage: `url(${staticUrl}/img/media/landing-square/landing-square_${section2Image2}.jpg)` }}>
                                        <div className="overlay absolute inset-0 flex items-end justify-center opacity-0 hover:opacity-100 transition-opacity hover:cursor-pointer">
                                            <div className="relative h-[50%] bg-slate-950 w-full translate-y-0 opacity-60"></div>
                                            <div className="absolute h-[50%] w-full translate-y-0 opacity-100">
                                                <div className="overlay-text text-white text-2xl xl:text-5xl font-light uppercase tracking-widest flex h-full items-center justify-center">
                                                    <p>{section2Text2}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a href={`/categories/${section2URL3}`}>
                                    <div className="relative aspect-square bg-cover bg-center" style={{ backgroundImage: `url(${staticUrl}/img/media/landing-square/landing-square_${section2Image3}.jpg)` }}>
                                        <div className="overlay absolute inset-0 flex items-end justify-center opacity-0 hover:opacity-100 transition-opacity hover:cursor-pointer">
                                            <div className="relative h-[50%] bg-slate-950 w-full translate-y-0 opacity-60"></div>
                                            <div className="absolute h-[50%] w-full translate-y-0 opacity-100">
                                                <div className="overlay-text text-white text-2xl xl:text-5xl font-light uppercase tracking-widest flex h-full items-center justify-center">
                                                    <p>{section2Text3}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </section>
                        </div>
                    ) : null }

                    { section2Enabled ? (
                        <div className="w-full px-4 xl:px-8 2xl:px-44 py-8 xl:py-16">
                            <h2 className="text-3xl md:text-5xl xl:text-6xl uppercase text-kb-400 tracking-wider font-light pb-5 xl:pb-10">{section2Title}</h2>
                            {section2Line1 && (
                                <p className='md:text-lg xl:text-xl leading-7 xl:leading-9 text-kb-400 tracking-widest'>{section2Line1}</p>
                            )}
                            {/*
                            {section2Line2 && (
                                <p className='md:text-lg xl:text-xl leading-7 xl:leading-9 text-kb-400 tracking-widest'>{section2Line2}</p>
                            )}
                            {section2Line3 && (
                                <p className='md:text-lg xl:text-xl leading-7 xl:leading-9 text-kb-400 tracking-widest'>{section2Line3}</p>
                            )}
                            */}

                            <div
                                className='md:text-lg xl:text-xl leading-7 xl:leading-9 text-kb-400 tracking-widest'
                                dangerouslySetInnerHTML={{ __html: section2Line2 }}
                            />
                        </div>
                    ) : null }

                    { section3Enabled ? (
                        <div className="w-full py-8 xl:py-16 text-kb-400">
                            <div className='grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 gap-2'>
                                <a href={`/collections/${section3URL1}`}>
                                    <div className="relative aspect-square bg-cover bg-center bg-gray-100" style={{ backgroundImage: `url(${staticUrl}/img/media/landing-square/landing-square_${section3Image1}.jpg)` }}></div>
                                </a>
                                <a href={`/collections/${section3URL1}`}>
                                    <div className='bg-kb-100 h-full flex space-y-4 p-8 items-center'>
                                        <div className='space-y-6'>
                                            <div className='text-3xl tracking-widest'>{section3Title1}</div>
                                            <div className='text-lg leading-7 tracking-wider'>{section3Description1.length > 200 ? `${section3Description1.substring(0, 200)}...` : section3Description1 }</div>
                                        </div>
                                    </div>
                                </a>
                                <a href={`/collections/${section3URL2}`}>
                                    <div className="relative aspect-square bg-cover bg-center bg-gray-100" style={{ backgroundImage: `url(${staticUrl}/img/media/landing-square/landing-square_${section3Image2}.jpg)` }}></div>
                                </a>
                                <a href={`/collections/${section3URL2}`}>
                                    <div className='bg-kb-100 h-full flex space-y-4 p-8 items-center'>
                                        <div className='space-y-6'>
                                            <div className='text-3xl tracking-widest'>{section3Title2}</div>
                                            <div className='text-lg leading-7 tracking-wider'>{section3Description2.length > 200 ? `${section3Description2.substring(0, 200)}...` : section3Description2 }</div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    ) : null }

                </main>
            </Layout>
        </>
    );
    
}
 
export default Index;